.footer0-wrapper {
  background-color: rgb(56, 56, 56);
  padding-top: 1rem;
  padding-bottom: 1rem;
  overflow: hidden;
  .footer0 {
    height: 100%;
    padding: 0 24px;
    text-align: center;
    color: @template-footer-text-color;
    position: relative;
  }
}

.lebon-logoe svg{
  width: 5vw;
  height: auto;
}

.link-lebon {
  font-family: 'Londrina Solid', cursive;
  text-align: center;
  font-size: 1.7rem;
  color: #1BAAA1 !important;
}

@media screen and (max-width: 767px) {
  .footer0-wrapper {
    .footer0 {
      font-size: 12px;
      &.home-page {
        padding: 0;
      }
      >div {
        width: 90%;
        margin: auto;
      }
    }
  }
  .lebon-logoe svg{
    width: 20vw;
    height: auto;
  }
}
