@import url(https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.main-wrapper {
  padding: 2rem;
  background-color: #456794;
  opacity: 1;
  background-image: linear-gradient(30deg, #1BAAA1 12%, transparent 12.5%, transparent 87%, #1BAAA1 87.5%, #1BAAA1), linear-gradient(150deg, #1BAAA1 12%, transparent 12.5%, transparent 87%, #1BAAA1 87.5%, #1BAAA1), linear-gradient(30deg, #1BAAA1 12%, transparent 12.5%, transparent 87%, #1BAAA1 87.5%, #1BAAA1), linear-gradient(150deg, #1BAAA1 12%, transparent 12.5%, transparent 87%, #1BAAA1 87.5%, #1BAAA1), linear-gradient(60deg, #1BAAA177 25%, transparent 25.5%, transparent 75%, #1BAAA177 75%, #1BAAA177), linear-gradient(60deg, #1BAAA177 25%, transparent 25.5%, transparent 75%, #1BAAA177 75%, #1BAAA177);
  background-size: 32px 56px;
  background-position: 0 0, 0 0, 16px 28px, 16px 28px, 0 0, 16px 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-card {
  width: 50vw;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.main-card .ant-card-head-title {
  font-family: 'Londrina Solid', cursive;
  text-align: center;
  font-size: 2rem;
  color: #F04D44;
}
.main-card .ant-divider-inner-text {
  font-family: 'Londrina Solid', cursive;
  font-size: 1.5rem;
  color: #F04D44;
}
.title-section {
  font-family: 'Londrina Solid', cursive;
  text-align: center;
  font-size: 2rem;
  color: #F04D44 !important;
}
.main-img {
  padding-bottom: 1rem;
  text-align: center;
}
.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  border-top: 2px solid #1BAAA1 !important;
}
@media screen and (max-width: 767px) {
  .main-card {
    width: 80vw;
  }
}
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.banner2 {
  width: 100%;
  height: calc(100vh - 64px);
  position: relative;
  border-color: #666;
  background: #fff;
}
.banner2 .banner-anim {
  height: 100%;
}
.banner2 .queue-anim-leaving {
  position: relative !important;
  width: auto;
}
.banner2 .banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.banner2 .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: cover;
}
.banner2 .bg0 {
  background: url("https://images.pexels.com/photos/262347/pexels-photo-262347.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940") center;
  background-size: cover;
  height: 100%;
  width: calc(100% + 100px);
  left: -50px;
}
.banner2 .bg0:before {
  width: 100%;
  height: 100%;
  content: '';
  background: rgba(0, 0, 0, 0.35);
  display: block;
}
.banner2 .banner-user-elem .banner-user-title {
  font-size: 22px;
  top: 40%;
}
.banner2 .banner-user-elem .banner-user-text {
  top: 40%;
}
.banner2-page {
  position: relative;
  top: 20%;
  max-width: 1200px;
  margin: auto;
}
.banner2-text-wrapper {
  display: inline-block;
  font-size: 14px;
  color: #fff;
  width: 400px;
  text-align: left;
}
.banner2-text-wrapper .banner2-title {
  left: 0;
  margin: auto;
  position: relative;
  font-size: 56px;
}
.banner2-text-wrapper .banner2-content {
  word-wrap: break-word;
  margin: auto auto 20px;
  font-weight: lighter;
}
.banner2-text-wrapper button {
  border: 1px solid #fff;
  color: #fff !important;
  background: transparent;
  transition: background 0.45s cubic-bezier(0.55, 0.055, 0.675, 0.19), border 0.45s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  font-size: 16px;
  height: 36px;
}
.banner2-text-wrapper button span {
  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: text-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner2-text-wrapper button:hover {
  color: #fff !important;
  border-color: #1BAAA1 !important;
  background: #1BAAA1 !important;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), border 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner2-text-wrapper button:hover span {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
}
.banner2-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  z-index: 10;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
.banner-anim-thumb-default span {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
}
.banner2-lebon {
  font-size: 2rem;
  letter-spacing: 0.5rem;
}
.ant-btn-ghost {
  border: 1px solid #fff;
  color: #fff !important;
  background: transparent;
  transition: background 0.45s cubic-bezier(0.55, 0.055, 0.675, 0.19), border 0.45s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  font-size: 16px;
  height: 36px;
}
.ant-btn-ghost span {
  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: text-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-btn-ghost:hover {
  color: #fff !important;
  border-color: #1BAAA1 !important;
  background: #1BAAA1 !important;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), border 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-btn-ghost:hover span {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
}
@media screen and (max-width: 414px) {
  .banner2-text-wrapper {
    width: 90%;
    left: 0;
    margin: auto;
    padding: 0;
    text-align: center;
    display: block;
  }
  .banner2-text-wrapper .logo {
    width: 90%;
    left: 0;
  }
}
.content3-wrapper {
  min-height: 764px;
}
.content3-wrapper .content3 {
  height: 100%;
  overflow: hidden;
}
.content3-wrapper .content3 .title-content {
  text-align: center;
}
.content3-wrapper .content3-block-wrapper {
  position: relative;
}
.content3-wrapper .content3-block-wrapper .content3-block {
  display: inline-block;
  padding: 48px 24px;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-icon {
  display: inline-block;
  width: 15%;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-text {
  width: 85%;
  display: inline-block;
  padding-left: 8%;
}
.content3-wrapper .content3-block-wrapper .content3-block.clear-both {
  clear: both;
}
.content-strong {
  color: #1BAAA1 !important;
  font-size: 1rem;
}
.div-content {
  width: 40vw;
  margin: auto;
}
.contentStyle {
  height: 300px;
  width: auto;
  text-align: center;
  background-color: #364d79;
}
.first {
  background-image: url(/static/media/01.ddf91577.png);
  background-size: cover;
}
.second {
  background-image: url(/static/media/02.e7316b7a.png);
  background-size: cover;
}
.three {
  background-image: url(/static/media/03.30668cd0.png);
  background-size: cover;
}
.four {
  background-image: url("https://images.pexels.com/photos/439391/pexels-photo-439391.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
  background-size: cover;
}
.f1 {
  background-image: url(/static/media/01.24ea934a.png);
  background-size: cover;
}
.f2 {
  background-image: url(/static/media/02.547ebac1.png);
  background-size: cover;
}
.f3 {
  background-image: url(/static/media/03.b82b6dd2.png);
  background-size: cover;
}
.f4 {
  background-image: url(/static/media/04.37fc79a3.png);
  background-size: cover;
}
.s1 {
  background-image: url(/static/media/01.b973780c.png);
  background-size: cover;
}
.s2 {
  background-image: url(/static/media/02.83392791.png);
  background-size: cover;
}
.s3 {
  background-image: url(/static/media/03.e5dbafc9.png);
  background-size: cover;
}
.s4 {
  background-image: url(/static/media/04.c4a9851f.png);
  background-size: cover;
}
.s5 {
  background-image: url(/static/media/05.289626e0.png);
  background-size: cover;
}
.s6 {
  background-image: url(/static/media/06.af974efc.png);
  background-size: cover;
}
.typo-align {
  text-align: justify;
}
.img-div {
  width: 30vw;
  margin: auto;
}
.typo-align-c {
  text-align: center;
}
.title-section-s {
  font-family: 'Londrina Solid', cursive;
  text-align: center;
  font-size: 1.7rem;
  color: #1BAAA1 !important;
}
@media screen and (max-width: 767px) {
  .content3-wrapper {
    min-height: 1080px;
  }
  .content3-wrapper .content3-block-wrapper {
    margin: 20px auto;
    height: auto;
  }
  .content3-wrapper .content3-block-wrapper .content3-block .content3-title {
    font-size: 20px;
  }
  .content3-wrapper .content3-block-wrapper .content3-block.queue-anim-leaving {
    position: relative !important;
  }
  .div-content {
    width: 80vw;
    margin: auto;
  }
  .contentStyle {
    height: 200px;
    width: auto;
  }
  .img-div {
    width: 80vw;
  }
}
.footer0-wrapper {
  background-color: #383838;
  padding-top: 1rem;
  padding-bottom: 1rem;
  overflow: hidden;
}
.footer0-wrapper .footer0 {
  height: 100%;
  padding: 0 24px;
  text-align: center;
  color: #999;
  position: relative;
}
.lebon-logoe svg {
  width: 5vw;
  height: auto;
}
.link-lebon {
  font-family: 'Londrina Solid', cursive;
  text-align: center;
  font-size: 1.7rem;
  color: #1BAAA1 !important;
}
@media screen and (max-width: 767px) {
  .footer0-wrapper .footer0 {
    font-size: 12px;
  }
  .footer0-wrapper .footer0.home-page {
    padding: 0;
  }
  .footer0-wrapper .footer0 > div {
    width: 90%;
    margin: auto;
  }
  .lebon-logoe svg {
    width: 20vw;
    height: auto;
  }
}

